<template>
  <b-container>
    <b-row class="m-5" align-h="center">
      <h1>Kalender der letzten Jahre</h1>
    </b-row>
    <b-row class="m-3" align-h="center">
      <p>
        Um nach Möglichkeit nicht jedes Jahr die gleiche Person zu beschenken, kannst du hier angeben, wem du in den letzten drei Jahren einen Kalender geschenkt hast.<br/>
        Wenn du dir unsicher bist, wähle in diesem Jahr einfach keine Person aus. 
      </p>
    </b-row>
    <b-row class="m-3" align-h="center">
      <b-col>
      <b-form-group
        id="selectHistoryYear1Group"
        :label="historyYear1"
        label-for="selectHistoryYear1"
      >
        <b-form-select
          id="selectHistoryYear1"
          v-model="selectedHistoryYear1"
          :options="historyOptions"
          value-field="playerId"
          text-field="name"
          stacked
        >
        </b-form-select>
      </b-form-group>
      </b-col>
      <b-col>
      <b-form-group
        id="selectHistoryYear2Group"
        :label="historyYear2"
        label-for="selectHistoryYear2"
      >
        <b-form-select
          id="selectHistoryYear2"
          v-model="selectedHistoryYear2"
          :options="historyOptions"
          value-field="playerId"
          text-field="name"
          stacked
        >
        </b-form-select>
      </b-form-group>
      </b-col>
      <b-col>
      <b-form-group
        id="selectHistoryYear3Group"
        :label="historyYear3"
        label-for="selectHistoryYear3"
      >
        <b-form-select
          id="selectHistoryYear3"
          v-model="selectedHistoryYear3"
          :options="historyOptions"
          value-field="playerId"
          text-field="name"
          stacked
        >
        </b-form-select>
      </b-form-group>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-button id="historyButton" variant="danger" @click="submit">
        Weiter
      </b-button>
    </b-row>
    <b-row align-h="center"> </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "History",
  data: function () {
    return {
      selectedHistoryYear1: "",
      selectedHistoryYear2: "",
      selectedHistoryYear3: "",
      historyOptions: this.getPlayers(),
    };
  },
  methods: {
    ...mapGetters(["getPlayers", "getHistory"]),
    ...mapActions(["storeHistory"]),
    submit: function () {
      const history = [];
      if (this.selectedHistoryYear1) {
        history.push({
          year: new Date().getFullYear() - 1,
          player: this.selectedHistoryYear1,
        });
      }
      if (this.selectedHistoryYear2) {
        history.push({
          year: new Date().getFullYear() - 2,
          player: this.selectedHistoryYear2,
        });
      }
      if (this.selectedHistoryYear3) {
        history.push({
          year: new Date().getFullYear() - 3,
          player: this.selectedHistoryYear3,
        });
      }
      this.storeHistory(history).then(() => {
        this.$router.push({ name: "Overview" });
      });
    },
    getHistoryYear1() {
      return new Date().getFullYear() - 1;
    },
    getHistoryYear2() {
      return new Date().getFullYear() - 2;
    },
    getHistoryYear3() {
      return new Date().getFullYear() - 3;
    },
  },
  computed: {
    historyYear1: function () {
      return this.getHistoryYear1().toString() + ":";
    },
    historyYear2: function () {
      return this.getHistoryYear2().toString() + ":";
    },
    historyYear3: function () {
      return this.getHistoryYear3().toString() + ":";
    },
  },
  mounted() {
    const history = this.getHistory();

    const year1 = history.filter(item => item.year == this.getHistoryYear1())[0];
    if (year1) {
      this.selectedHistoryYear1 = year1.player;
    }
    const year2 = history.filter(item => item.year == this.getHistoryYear2())[0];
    if (year2) {
      this.selectedHistoryYear2 = year2.player;
    }
    const year3 = history.filter(item => item.year == this.getHistoryYear3())[0];
    if (year3) {
      this.selectedHistoryYear3 = year3.player;
    }
    
  }
};
</script>

<style>
</style>