<template>
  <b-container v-if="dataLoaded">
    <b-row class="m-5">
      <h1>Zusammen&shy;fassung</h1>
    </b-row>
    <b-row class="m-3">
      <p>
        Hier sind noch mal deine Angaben zusammengefasst.<br/>
        Sobald die Verlosung stattgefunden hat, wird hier auch die Person angezeigt, der du als nächstes einen Kalender schenken darfst.
      </p>
    </b-row>
    <b-row class="m-3">
      <b-col md="3">
        <h1>Los:</h1>
      </b-col>
      <b-col>
        <h1>{{ match }}</h1>
      </b-col>
    </b-row>
    <br/>
    <b-row class="m-3">
      <b-col md="3">
        <h2>Wunsch:</h2>
      </b-col>
      <b-col>
        {{ wish }}
      </b-col>
      <b-col>
        <b-button v-if="editable" variant="danger" @click="changeWish">Ändern</b-button>
      </b-col>
    </b-row>
    <br/>
    <b-row class="m-3">
      <b-col md="3"><h2>{{ historyYear1 }}</h2></b-col>
      <b-col>{{ historyYear1Name }}</b-col>
      <b-col>
        <b-button v-if="editable" variant="danger" @click="changeHistory">Ändern</b-button>
      </b-col>
    </b-row>  
    <b-row class="m-3">
      <b-col md="3"><h2>{{ historyYear2 }}</h2></b-col>
      <b-col>{{ historyYear2Name }}</b-col>
    </b-row>
    <b-row class="m-3">
      <b-col md="3"><h2>{{ historyYear3 }}</h2></b-col>
      <b-col>{{ historyYear3Name }}</b-col>
    </b-row>
    <b-row class="m-5">
      <amplify-sign-out></amplify-sign-out>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Overview",

  data() {
    return {
    
    
    };
  },

  computed: {
    wish() {
      if (this.getWish()) {
        return this.getWishName();
      }
      return "Nicht ausgewählt";
    },
    match() {
      if (this.getMatch()) {
        return this.getMatchName();
      }
      return "Es wurde noch nicht gelost"
    },
    editable() {
      if (this.getMatch()) {
        return false;
      }
      return true;
    },
    historyYear1: function () {
      return this.getHistoryYear1().toString() + ":";
    },
    historyYear2: function () {
      return this.getHistoryYear2().toString() + ":";
    },
    historyYear3: function () {
      return this.getHistoryYear3().toString() + ":";
    },
    historyYear1Name: function () {
      const year = this.getHistory().filter(item => item.year == this.getHistoryYear1())[0];
      if (year) {
        return this.getPlayers().filter(p => p.playerId == year.player)[0].name;
      }
      return "Nicht ausgewählt";
    },
    historyYear2Name: function () {
      const year = this.getHistory().filter(item => item.year == this.getHistoryYear2())[0];
      if (year) {
        return this.getPlayers().filter(p => p.playerId == year.player)[0].name;
      }
      return "Nicht ausgewählt";
    },
    historyYear3Name: function () {
      const year = this.getHistory().filter(item => item.year == this.getHistoryYear3())[0];
      if (year) {
        return this.getPlayers().filter(p => p.playerId == year.player)[0].name;
      }
      return "Nicht ausgewählt";
    },
    dataLoaded() {
      return this.getDataLoaded() >= 5;
    },
  },

  methods: {
    ...mapGetters(["getWish","getWishName", "getHistory", "getPlayers", "getMatch","getMatchName", "getDataLoaded"]),
    changeWish: function () {
      this.$router.push({ name: "Wish" });
    },
    changeHistory: function () {
      this.$router.push({ name: "History" });
    },
    getHistoryYear1() {
      return new Date().getFullYear() - 1;
    },
    getHistoryYear2() {
      return new Date().getFullYear() - 2;
    },
    getHistoryYear3() {
      return new Date().getFullYear() - 3;
    },
  },
  mounted() {    
  }
};
</script>

<style>
</style>