<template>
  <div v-if="dataLoaded">{{forward()}}</div>
  <div v-else>Daten werden geladen</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Home",
  methods: {
    ...mapGetters(["getMatch", "getDataLoaded"]),
    forward() {
      const check = this.getMatch();
      if (check) {
        this.$router.push({ name: "Overview" });
      } else {
        this.$router.push({ name: "Start" });
      }
      return;
    }
  },
  computed: {
    dataLoaded() {
      return this.getDataLoaded() >= 5;
    },
  },
  mounted: function () {
  }
};
</script>
