import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Start from '../views/Start.vue'
import Wish from '../views/Wish.vue'
import History from '../views/History.vue'
import Overview from '../views/Overview.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/start',
    name: 'Start',
    component: Start
  },
  {
    path: '/wish',
    name: 'Wish',
    component: Wish
  },
  {
    path: '/history',
    name: 'History',
    component: History
  },
  {
    path: '/overview',
    name: 'Overview',
    component: Overview
  },
]

const router = new VueRouter({
  routes
})

export default router
