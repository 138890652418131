<template>
  <b-container>
    <b-row class="m-5" align-h="center">
      <h1>Advents&shy;kalender&shy;wichteln</h1>
    </b-row>
    <b-row class="m-3" align-h="center">
      <h2>Hallo {{ userName }},</h2>
    </b-row>
    <b-row class="m-1">
      <p>
        auch in diesem Jahr wollen wir uns gegenseitig eine Freude machen und
        einem Mitglied der Familie einen Adventskalender schenken.<br />
        Doch auch schöne Traditionen gehen mit der Zeit, deshalb findet die
        Verlosung dieses Jahr digital statt. Und mit dieser Neuerung soll auch
        etwas Abwechselung in die Verlosung gebracht werden, weil viele von uns
        hatten in den letzten Jahren das Gefühl, dass sie bestimmte Personen
        noch nie beschenken durften und andere dafür schon mehrfach.
      </p>
      <p>
        Du kannst auf den nächsten Seiten zunächst einen Wunsch angeben, wem du
        in diesem Jahr am liebsten einen Kalender schenken möchtest und wem du
        in den letzten 3 Jahren bereits einen Kalender geschenkt hast.<br />
        Dein Wunsch und deine letzten 3 Personen werden dann in der Verlosung,
        wenn möglich, berücksichtigt.<br />
        Die Verlosung findet statt, wenn alle Mitspieler ihre Wünsche hinterlegt
        haben. Du erhälst dann eine weitere Information und dein
        Familienmitglied wird hier angezeigt.<br />
      </p>
      <p>
        Das Ganze ist ein Versuch für dieses Jahr, mal sehen ob es klappt und
        alle am Ende zufrieden sind.
      </p>
    </b-row>
    <b-row class="m-5" align-h="center">
      <b-button variant="danger" :disabled="showNext" v-on:click="start"
        >Bin dabei</b-button
      >
    </b-row>
  </b-container>
</template>

<script>
import { BButton } from "bootstrap-vue";

import { mapGetters } from "vuex";

export default {
  name: "Start",
  components: {
    BButton,
  },
  computed: {
    userName() {
      return this.getUserName();
    },
    showNext() {
      return this.getDataLoaded() < 5;
    },
  },
  methods: {
    ...mapGetters(["getUserName", "getDataLoaded"]),
    start() {
      this.$router.push({ name: "Wish" });
    },
  },
};
</script>

<style lang="scss">
</style>