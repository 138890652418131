<template>
  <amplify-authenticator>
    <amplify-sign-in :hide-sign-up="true" slot="sign-in"></amplify-sign-in>
    <div>
      <router-view />
    </div>
  </amplify-authenticator>
</template>

<script>
import { Auth } from "aws-amplify";
import { Hub, Logger } from "aws-amplify";

import { mapActions } from "vuex";

export default {
  name: "App",
  methods: {
    ...mapActions(["storeUserData", "loadPlayers", "loadWish", "loadMatch", "loadHistory"]),
    initStore() {
      const logger = new Logger("App-Logger");

      logger.info("initStore");
      this.storeUserData().then(() => {
        this.loadPlayers();
        this.loadWish();
        this.loadMatch();
        this.loadHistory();
      });
    }
  },
  beforeCreate() {
    const logger = new Logger("App-Logger");

    try {
      Auth.currentAuthenticatedUser().then(() => {
        this.initStore();
        logger.info("Init store without sign-in");
      });
    } catch (err) {
      undefined;
    }

    const listener = (data) => {
      if (data.payload.event === "signIn") {
        this.initStore();
        logger.info("Init store with sign-in");
      }
    };

    Hub.listen("auth", listener);
  },
};
</script>

<style lang="scss">
</style>
