<template>
  <b-container>
    <b-row class="m-5" align-h="center">
      <h1>Mein Wunsch</h1>
    </b-row>
    <b-row class="m-3" align-h="center">
      <p>
        Hier kannst du die Person auswählen, der du gerne als nächstes einen Kalender schenken möchtest.<br/>
        Wenn es dir egal ist und das Los frei entscheiden soll, kannst du auch gar keine Person auswählen.
      </p>
    </b-row>
    <b-row align-h="center">
      <b-form-radio-group
        v-model="selectedWish"
        :options="wishOptions"
        value-field="playerId"
        text-field="name"
        size="lg"
        stacked
      >
      </b-form-radio-group>
    </b-row>
    <b-row class="m-5" align-h="center">
      <b-button id="wishButton" variant="danger" @click="submit">
        Weiter
      </b-button>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Wish",
  data() {
    return {
      selectedWish: "",
    };
  },
  methods: {
    ...mapGetters(["getPlayers", "getWish"]),
    ...mapActions(["storeWish"]),
    submit() {
      if (this.selectedWish) {
        this.storeWish(this.selectedWish);
      }
      this.$router.push({ name: "History" });
    },
  },
  computed: {
    wishOptions() {
      const players = this.getPlayers();
      return players;
    },
  },
  mounted() {
    this.selectedWish = this.getWish();
  },
};
</script>

<style>
</style>