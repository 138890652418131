export default {
    Auth: {
        region: 'eu-central-1',
        userPoolId: 'eu-central-1_YJ5MviZFv',
        userPoolWebClientId: '4s3j36j4c59j6lft1oe8o51d4f'
    },
    
    API: {
        endpoints: [
            {
                name: "AdventskalenderAPI",
                endpoint: "https://cl2esibgqh.execute-api.eu-central-1.amazonaws.com"
            }
        ]
    }
}