import Vue from 'vue'

import { LayoutPlugin } from 'bootstrap-vue'
import { FormPlugin } from 'bootstrap-vue'
import { FormGroupPlugin } from 'bootstrap-vue'
import { FormInputPlugin } from 'bootstrap-vue'
import { FormRadioPlugin } from 'bootstrap-vue'
import { FormSelectPlugin } from 'bootstrap-vue'
import { ButtonPlugin } from 'bootstrap-vue'

import './assets/styles.scss'

import App from './App.vue'

import router from './router'

import store from './store';

import Amplify from 'aws-amplify'
import '@aws-amplify/ui-vue'

import aws_config from './aws-config'
Amplify.configure(aws_config)
Amplify.Logger.LOG_LEVEL = 'INFO';

Vue.config.productionTip = false

Vue.use(LayoutPlugin)
Vue.use(FormPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormSelectPlugin)
Vue.use(ButtonPlugin)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
