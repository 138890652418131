import Vuex from 'vuex';
import Vue from 'vue';

import { Auth, API } from "aws-amplify";

Vue.use(Vuex);

const state = {
    name: "",
    playerId: "",
    players: [],
    wish: "",
    match: "",
    history: [],
    dataLoaded: 0,
};

const getters = {
    getUserName(state) {
        return state.name;
    },
    getPlayers(state) {
        return state.players.filter(player => player.playerId !== state.playerId);
    },
    getWish(state) {
        return state.wish;
    },
    getWishName(state) {
        return state.players.filter(player => player.playerId == state.wish)[0].name;
    },
    getMatch(state) {
        return state.match;
    },
    getMatchName(state) {
        if (state.match) {
            return state.players.filter(player => player.playerId == state.match)[0].name;
        }
        return "";
    },
    getHistory(state) {
        return state.history;
    },
    getDataLoaded(state) {
        return state.dataLoaded;
    }
};

const actions = {
    storeUserData({ commit }) {
        return Auth.currentAuthenticatedUser()
            .then((user) => {
                const u = {
                    name: user.attributes["name"],
                    playerId: user.attributes["custom:playerId"],
                };
                commit("setNameAndPlayerId", u);
                this.state.dataLoaded = this.state.dataLoaded + 1;
            })
            .catch((err) => console.log(err));
    },
    async loadPlayers({ commit }) {
        const apiName = "AdventskalenderAPI";
        const path = "/get-players";
        const data = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            },
        };
        console.log("Call /get-players")

        API.get(apiName, path, data)
            .then((players) => {
                commit("setPlayers", players);
                this.state.dataLoaded = this.state.dataLoaded + 1;
            })
            .catch((error) => {
                console.log(error.response);
            });
    },
    async loadWish({ commit }) {
        if (this.state.playerId) {
            const apiName = "AdventskalenderAPI";
            const path = "/get-wish";
            const data = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                },
                queryStringParameters: {
                    player: this.state.playerId,
                    year: new Date().getFullYear()
                }
            }

            console.log("Call /get-wish");

            return API.get(apiName, path, data)
                .then((response) => {
                    if (response.length == undefined) {
                        commit("setWish", response.wishPlayer);
                    }
                    this.state.dataLoaded = this.state.dataLoaded + 1;
                })
                .catch(error => {
                    console.log(error.response);
                });
        }
    },
    async loadMatch({ commit }) {
        if (this.state.playerId) {
            const apiName = "AdventskalenderAPI";
            const path = "/get-match";
            const data = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                },
                queryStringParameters: {
                    player: this.state.playerId,
                    year: new Date().getFullYear()
                }
            }

            console.log("Call /get-match");

            return API.get(apiName, path, data)
                .then((response) => {
                    if (response.length == undefined) {
                        commit("setMatch", response.matchPlayer);
                    }
                    this.state.dataLoaded = this.state.dataLoaded + 1;
                })
                .catch(error => {
                    console.log(error.response);
                });
        }
    },
    async loadHistory({ commit }) {
        if (this.state.playerId) {
            const apiName = "AdventskalenderAPI";
            const path = "/get-history";
            const data = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                },
                queryStringParameters: {
                    player: this.state.playerId,
                }
            }

            console.log("Call /get-history");

            return API.get(apiName, path, data)
                .then((response) => {
                    if (response.length == 1) {
                        commit("setHistory", response[0].historyItems);
                    }
                    this.state.dataLoaded = this.state.dataLoaded + 1;
                })
                .catch(error => {
                    console.log(error.response);
                });
        }
    },
    async storeWish({ commit }, wish) {
        const apiName = "AdventskalenderAPI";
        const path = "/create-wish";
        const data = {
            headers: {
                Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            },
            body: {
                player: this.state.playerId,
                year: new Date().getFullYear(),
                wishPlayer: wish
            }
        }

        console.log("Call /create-wish", data)

        API.post(apiName, path, data)
            .then(() => {
                commit("setWish", wish);
            })
            .catch(error => {
                console.log(error.response);
            });
    },
    async storeHistory({ commit }, history) {
        const apiName = "AdventskalenderAPI";
        const path = "/create-history";

        for (let historyItem of history) {
            const data = {
                headers: {
                    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                },
                body: {
                    player: this.state.playerId,
                    historyItem: historyItem
                }
            }

            console.log("Call /create-history")

            API.post(apiName, path, data)
                .then(() => {
                    undefined;
                })
                .catch(error => {
                    console.log(error.response);
                });
        }
        commit("setHistory", history);
    }
};

const mutations = {
    setNameAndPlayerId(state, user) {
        state.name = user.name;
        state.playerId = user.playerId;
    },
    setPlayers(state, players) {
        state.players = players;
    },
    setWish(state, wish) {
        state.wish = wish;
    },
    setMatch(state, match) {
        state.match = match;
    },
    setHistory(state, history) {
        state.history = history;
    }
};


export default new Vuex.Store({
    state,
    getters,
    actions,
    mutations
});